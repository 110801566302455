import logoLink from '../resources/roche.svg';

export const cssVariables = {
    active: '#0B41CD',
    inactive: '#C40000',

    primary_light: '#F0F4FD',
    primary: '#0B41CD',
    primary_dark: '#6084E2',
    text_on_primary: '#fff',
    primary_transparent: '#F0F4FD',

    secondary: '#C40000',
    secondary_light: '#FF8782',
    text_on_secondary: 'white',
    secondary_transparent: '#f9e6e6',

    font_color: '#000000',
    link_color: '#0B41CD',

    font: 'Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#0B41CD',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#C40000',    

    dropdown_background: '#F0F4FD',

};

export const logo = logoLink;
